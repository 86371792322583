<template>
  <b-card>
    <b-overlay
      :show="!invoiceData.merged"
      rounded="sm"
    >
      <div class="invoice-register-table pt-1">
        <div class="float-left ir-btn-wrapper">
          <b-button
            variant="primary"
            class="ir-btn billing-top-btn"
          >
            Create Profile
          </b-button>
        </div>
        <!-- search input -->
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">Search</label>
              <b-form-input
                v-model="searchTerm"
                placeholder="Search"
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </div>
        <!-- table -->
        <vue-good-table
          v-if="invoiceData.merged"
          :columns="columns"
          :rows="item"
          :search-options="{
            enabled: false,
            externalQuery: searchTerm }"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
          class="mt-2"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >

            <!-- Column: Common -->
            <span>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap mt-1">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['10','15','20']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    </b-overlay>
    <b-modal
      id="export-modal"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      title="Export Table"
    >
      <div class="text-center">
        <b-button
          variant="primary"
          class="activation-btn"
        >
          To PDF
        </b-button><br>
        <b-button
          variant="primary"
          class="activation-btn mt-1 mb-1"
        >
          To Excel
        </b-button>
      </div>
    </b-modal>

    <!-- Search Filter -->
    <b-modal
      id="search-filter-modal"
      cancel-variant="outline-secondary"
      ok-only
      ok-title="Apply"
      centered
      title="Search Filter"
    >
      <b-form>
        <b-row>
          <b-col>
            <b-form-group
              label="Issue Start Date:"
              label-for="referral-type"
            >
              <date-picker
                v-model="filter.issueStart"
                format="YYYY-MM-DD"
                type="date"
                value-type="format"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="Issue End Date:"
              label-for="referral-type"
            >
              <date-picker
                v-model="filter.issueEnd"
                format="YYYY-MM-DD"
                type="date"
                value-type="format"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <hr>
        <b-row>
          <b-col>
            <b-form-group
              label="Due Start Date:"
              label-for="referral-type"
            >
              <date-picker
                v-model="filter.dueStart"
                format="YYYY-MM-DD"
                type="date"
                value-type="format"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="Due End Date:"
              label-for="referral-type"
            >
              <date-picker
                v-model="filter.dueEnd"
                format="YYYY-MM-DD"
                type="date"
                value-type="format"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <hr>
        <b-row>
          <b-col>
            <b-form-group
              label="Paid On:"
              label-for="referral-type"
            >
              <date-picker
                v-model="filter.paidOn"
                format="YYYY-MM-DD"
                type="date"
                value-type="format"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="Status:"
              label-for="referral-type"
            >
              <b-form-select
                id="referral-type"
                v-model="filter.status"
                :options="[{ value: 'paid', text: 'Paid' },{ value: 'pending', text: 'Pending' },{ value: 'overdue', text: 'Overdue' }]"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group
              label="Clients:"
              label-for="referral-type"
            >
              <b-form-select
                id="referral-type"
                v-model="filter.clients"
                :options="[{ value: 'registered', text: 'Registered' },{ value: 'external', text: 'External' }]"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              label="Invoice Type:"
              label-for="referral-type"
            >
              <b-form-select
                id="referral-type"
                v-model="filter.invoiceType"
                :options="[{ value: 'malta', text: 'Malta' },{ value: 'romania', text: 'Romania' }]"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>

  </b-card>
</template>

<script>
import {
  // eslint-disable-next-line no-unused-vars
  BPagination, BFormSelect, BCard, BOverlay, BButton, BBadge, BModal, BForm, BFormGroup, BRow, BCol, BFormInput,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import moment from 'moment'
import vSelect from 'vue-select'

import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BOverlay,
    BButton,
    BCard,
    BModal,
    BForm,
    BFormGroup,
    BFormSelect,
    BRow,
    BCol,
    BFormInput,
    DatePicker,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
  },
  data() {
    return {
      pageLength: 20,
      dir: false,
      columns: [
        {
          label: 'Profile Name',
          field: 'profileName',
          sortable: false,
        },
        {
          label: 'User Name',
          field: 'userName',
          sortable: false,
        },
        {
          label: 'User Address',
          field: 'userAddress',
          sortable: false,
        },
        {
          label: 'Country',
          field: 'country',
          sortable: false,
        },
        {
          label: 'User VAT',
          field: 'userVat',
          sortable: false,
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      item: [],
      rows: [],
      filter: {
        issueStart: null,
        issueEnd: null,
        dueStart: null,
        dueEnd: null,
        paidOn: null,
        status: null,
        clients: null,
        invoiceType: null,
      },
      searchTerm: '',
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Registered : 'light-success',
        External      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    users() {
      return this.$store.state.users.expiredUsers
    },
    invoiceData() {
      return this.$store.state.invoice.invoiceData
    },
  },
  created() {
    this.fetchInvoices()
  },
  methods: {
    formatDate(Date) {
      return moment(Date).format('DD MMM YYYY')
    },
    fetchInvoices() {
      this.$store.dispatch('invoice/fetchInvoices')
        .catch(error => { console.log(error) })
    },
    cFirst(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';

.mx-datepicker {
  width: 100% !important;
}

.invoice-register-table {
  thead tr{
    background: #f3f2f7;
  }

  th, td {
    font-size: 14px;
    text-align: center !important;
    vertical-align: middle !important;
  }

  td {
    height: 62px;
  }
}

.ir-btn-wrapper {
  width: 30rem;

  .ir-btn {
    width: 100%;
    max-width: 148px;
  }
}

.activation-btn {
  width: 100%;
  max-width: 16rem;
}
</style>
